<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container container-small">
        <header class="admin-header">
          <h1>Benutzer</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ schedule.name }}</h3>
            </header>
            <div class="content-body">
              <ul style="margin: 0; padding: 0">
                <li v-for="slot in schedule.slots" v-bind:key="slot.id" style="margin: 0 0 10px 0; padding: 0; list-style-type: none">
                  <span v-if="slot.start_day == 1">Montag</span>
                  <span v-if="slot.start_day == 2">Dienstag</span>
                  <span v-if="slot.start_day == 3">Mittwoch</span>
                  <span v-if="slot.start_day == 4">Donnerstag</span>
                  <span v-if="slot.start_day == 5">Freitag</span>
                  <span v-if="slot.start_day == 6">Samstag</span>
                  <span v-if="slot.start_day == 0">Sonntag</span> / Start: {{ slot.start_time }} Uhr für {{ slot.duration }} Minuten /
                  <span v-if="slot.slot_type == 'sales'">{{ $t("global.adExternal") }}</span>
                  <span v-if="slot.slot_type == 'internal'">{{ $t("global.adInternal") }}</span
                  >/ Playlist: {{ slot.playlist_name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "schedules",
  components: {
    AdminNav,
  },
  data() {
    return {
      schedule: {},
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/schedules/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.schedule = response.data.schedule;
        });
    },
    /*update_user: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid, this.user, { headers: { Authorization: this.$store.getters.getToken } })
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'notification',
          type: 'success',
          text: "Erfolgreich gespeichert."
        });
        this.get_data();
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })

    },*/
  },
  mounted() {
    this.get_data();
  },
};
</script>
